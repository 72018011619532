
import WheelComponent from 'react-wheel-of-prizes'
import 'react-wheel-of-prizes/dist/index.css' 

const Spin = () => {
    const segments = [
        'Swimsuit/Bikini',
        'Grad School Outfit',
        'Gym Outfit',
        'Pair of Shoes',
        'Mani/Pedi',
        'Jewelry',
        'Swimsuit/Bikini',
        'Grad School Outfit',
        'Gym Outfit',
        'Pair of Shoes',
        'Mani/Pedi',
        'Jewelry',
        'Swimsuit/Bikini',
        'Grad School Outfit',
        'Gym Outfit',
        'Pair of Shoes',
        'Mani/Pedi',
        'Jewelry'
      ]
      const segColors = [
        '#c7782a',
        '#6e34eb',
        '#19706a',
        '#d11d77',
        '#841cd4',
        '#507d5c',
        '#c7782a',
        '#6e34eb',
        '#19706a',
        '#d11d77',
        '#841cd4',
        '#507d5c',
        '#c7782a',
        '#6e34eb',
        '#19706a',
        '#d11d77',
        '#841cd4',
        '#507d5c'
      ]
      const onFinished = (winner) => {
        console.log(winner)
      }
      return (
        <div>
          
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <table style={{width: 'auto', border: "1px solid black", }}>
        <th>Rewards </th>
          <tr stye={{border: "1px solid black"}}>Swimsuit/Bikini</tr>
        <tr stye={{border: "1px solid black"}}>Grad Schoolt Outfit</tr>
        <tr stye={{border: "1px solid black"}}>Gym Outfit</tr>
        <tr stye={{border: "1px solid black"}}>Pair of Shoes</tr>
        <tr stye={{border: "1px solid black"}}>Mani/Pedi</tr>
        <tr stye={{border: "1px solid black"}}>Jewelry</tr>
      </table>
      </div>
        <WheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment=''
          onFinished={(winner) => onFinished(winner)}
          primaryColor='black'
          contrastColor='white'
          buttonText='Spin'
          isOnlyOnce={true}
          size={300}
          upDuration={100}
          downDuration={1000}
          
        />
        </div>
    
      
    </div>
      )
}

export default Spin;