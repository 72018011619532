import {Jumbotron, Container, Row, Col} from 'react-bootstrap'
import Silly from '../img/Silly.jpg'
import Beaut from '../img/Beaut.jpg'
import Us from '../img/Us.jpg'

const Alyssa = () => {
    return (
        <Container>
        <Jumbotron className="justify-content-md-center" style={{marginTop: 10}}>
            <Row>
                <Col><h1>Alyssa Hochstatter, PhD.</h1></Col>
            </Row>
            <Row>
                <Col><p>Future doctor, and future wife!</p></Col>
            </Row>
            <Row>
                <Col><img style={{width:300, height:250}} src={Silly}></img></Col>
                <Col><img style={{width:300, height:250}} src={Beaut}></img></Col>
                <Col><img style={{width:300, height:250}} src={Us}></img></Col>
            </Row>
        </Jumbotron>
        </Container>
   
    )
}

export default Alyssa;