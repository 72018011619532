import Spin from './components/Spin'
import Alyssa from './components/Alyssa'
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const App =()=> {
  return (
    <Router>
    <div>
      <nav>
      <Button variant="outline-success"><Link to="/">Home</Link></Button>   
        <Button variant="outline-success"><Link to="/spin">GIFTS</Link></Button> 
      </nav>

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/spin">
          <Spin />
        </Route>
        <Route path="/">
          <Alyssa />  
        </Route>
      </Switch>
    </div>
  </Router>
  )
}

export default App;
